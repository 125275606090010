import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import "./ScrollBtn.css"


function ScrollButton() {

  const [visible, setVisible] = useState(false)

  function toggleVisible() {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (

    <button className='button'>
      <FaArrowCircleUp onClick={scrollToTop}
        style={{ display: visible ? 'block' : 'none' }} />
    </button>

  );
}

export default ScrollButton;