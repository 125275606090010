import "./Reviews.css"

function Reviews() {
    return (
        <section className="content__section" id="reviews">
            <div className="sw-app" data-app="5fa04390707916ae385eecc29754ad18"></div>
        </section>
    )
}

export default Reviews